$(document).ready(function () {
  // pop up menu
  $("#aAff").click(function () {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>代理合作',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "85%"],
      content: $("#m1"),
    });
  });

  $("#aPromo").click(function () {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>最新优惠',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "85%"],
      content: $("#youhui"),
    });
  });

  $("#aFaq").click(function () {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>常见问题',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "80%"],
      content: $("#m2"),
    });
  });

  $("#aAbtUs").click(function () {
    layer.open({
      type: 1,
      title: '<span class="gundong"></span>关于我们',
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "80%"],
      content: $("#m3"),
    });
  });

  //promo
  $(function () {
    if (window.promo) {
      renderPromo(window.promo);
    } else {
      Object.defineProperty(window, "promo", {
        set: function (data) {
          renderPromo(data);
        },
      });
    }

    function registerPromo(promoId) {
      api.registerPromo(promoId);
    }

    function renderPromo(promoData) {
      if (Array.isArray(promoData)) {
        var promoWrapper = $(".promo-wrapper");
        promoData.forEach(function (value, index) {
          var promoItem = $(`<div class="main_box" data-type="${value.type}"></div>`);

          var promoId = value.promoId;
          var requestToJoin = value.requestToJoin;
          var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

          promoItem.append(
            $(`
              ${
                value.imagePath
                  ? `
              <div class="icon sub-content-item-header">
                <img src="${value.imagePath}" />
              </div>
              `
                  : `
                <div class="sub-content-item-header">
                <h3>${value.title}</h3>
              </div>
                `
              }
              <div class="text nr" style="display: none;">
                ${value.content}
                ${
                  requestToJoin
                    ? `
                         <div
                           class="sub-content-item-footer"
                           style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;font-size: 14px"
                         >
                           ${
                             firstDepositPromoJoinMsg
                               ? `
                                 <div class="sub-content-item-footer--info" style="color: #555;">
                                   ${firstDepositPromoJoinMsg}
                                 </div>
                               `
                               : `
                                 <button
                                   class="sub-content-item-footer--register-promo"
                                   data-id="${promoId}"
                                   style="background-image: linear-gradient(#fdcd2f, #ffcc2f, #fefaca);cursor:pointer;color:#000;outline:0;height:35px; width:120px; border-radius:5px; border:0;"
                                 >
                                   申请参与
                                 </button>
                               `
                           }
                         </div>
                       `
                    : ``
                }
              </div>
             
            `)
          );
          promoWrapper.append(promoItem);
        });

        $(function () {
          $(".sub-content-item-footer--register-promo").on("click", function (e) {
            e.preventDefault();
            var promoId = $(this).data("id");
            registerPromo(promoId);
          });
        });

        $(document).on("click", ".main_box .sub-content-item-header", function (e) {
          e.preventDefault();
          var mainbox = $(this).closest(".main_box");
          if (mainbox.hasClass("on")) {
            mainbox.removeClass("on");
            mainbox.children(".bt").show();
            mainbox.children(".nr").slideUp();
          } else {
            mainbox.addClass("on");
            mainbox.children(".bt").hide();
            mainbox.children(".nr").slideDown();
          }
        });
      }
    }
  });
});

// Banner carousel
if (Pace) {
  Pace.on("done", initBanner);
} else {
  $(window).load(initBanner);
}

function initBanner() {
  initSwiper();
}

function initSwiper() {
  const bannerList = window.banner;
  const indexContainer = $(".swiper-wrapper");
  const isLoggedIn = $(".before-login.hide").length !== 0;

  bannerList &&
    bannerList !== 0 &&
    bannerList.forEach(function (item) {
      const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

      if (imageUrl) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */
        const banner = $(`
      <div class="swiper-slide">
        <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
        <div class="img-bg" style="background-image: url(${imageUrl})"></div>
      </div>
    `);

        banner.click(() => {
          if (redirectGroup === 1) {
            $("#aAff").click();
          } else if (redirectGroup === 4 && redirectUrl === "/faq") {
            $("#aFaq").click();
          } else {
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            });
          }
        });

        /**
         * index append 首页type banner, xycly promo.html no banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
        }
      }
    });

  if (bannerList && bannerList.length) {
    new Swiper(".swiper-container", {
      speed: 400,
      spaceBetween: 10,
      autoplay: {
        delay: 5000,
      },
      loop: true,
    });
  }
}
